<template>
  <div class="examination"
       v-show="showExData"
       style="display: none">
    <van-nav-bar :title="examinTitle"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft" />
    <div class="banner"></div>
    <div v-if="qsList1.length > 0"
         class="content"
         :class="can_switch_pre?'small_content':''">
      <!-- 题型信息 -->
      <div class="qsType">
        <!-- <span>{{ bigOrder[bgIdx] }}</span> -->
        <span class="typeName">{{ qsTypeIndexArr[qsList1[hisIdx].qsTypeIndex] }}、{{
            qsList1[hisIdx].qsType
          }}</span>
        <span class="grade"
              v-if="
            qsList1[hisIdx].qsType == '单选题' ||
            qsList1[hisIdx].qsType == '多选题' ||
            qsList1[hisIdx].qsType == '判断题'
          ">
          {{
            `(共${qsList1[hisIdx].icount}题，每题${qsList1[hisIdx].score}分，共${qsList1[hisIdx].totalscore}分)`
          }}
        </span>
        <span class="grade"
              v-if="
            qsList1[hisIdx].qsType == '填空题' ||
            qsList1[hisIdx].qsType == '问答题'
          ">
          {{
            `(共${qsList1[hisIdx].icount}题，共${qsList1[hisIdx].totalscore}分)`
          }}
        </span>
      </div>
      <!-- 题目内容详情 -->
      <div class="qsContent">
        <div class="qsTit"
             style="white-space: pre-wrap;">
          {{ `${qsList1[hisIdx].sqnum}.${qsList1[hisIdx].qstitle}` }}
        </div>
        <!-- 图片大小 width="10rem"
                   height="10rem" -->
        <div class="title_pic"
             v-show="qsList1[hisIdx].pic_guid">
          <van-image fit="contain"
                     :src="
              qsList1[hisIdx].pic_guid
                ? filesvrurl + '/' + qsList1[hisIdx].pic_guid + '.png'
                : ''
            "
                     @click="
              imagePreview(filesvrurl + '/' + qsList1[hisIdx].pic_guid + '.png')
            " />
        </div>
        <van-radio-group class="singleChoice"
                         v-model="radioAs"
                         @change="radioAnswer"
                         v-if="qsList1[hisIdx].qsType == '单选题'">
          <div v-for="(opt, opIdx) in qsList1[hisIdx].options"
               :key="opIdx">
            <van-radio :name="opt.id"><span class="iico">{{ abList[opIdx] }}</span>{{ opt.c }}</van-radio>
            <div class="option_pic"
                 v-show="qsList1[hisIdx].item_pics[opIdx]">
              <van-image width="100%"
                         height="100%"
                         :src="
                  qsList1[hisIdx].item_pics[opIdx]
                    ? filesvrurl +
                      '/' +
                      qsList1[hisIdx].item_pics[opIdx] +
                      '.png'
                    : ''
                "
                         @click="
                  imagePreview(
                    filesvrurl + '/' + qsList1[hisIdx].item_pics[opIdx] + '.png'
                  )
                " />
            </div>
          </div>
        </van-radio-group>
        <!-- 多选题 -->
        <van-checkbox-group v-model="checkList"
                            v-else-if="qsList1[hisIdx].qsType == '多选题'"
                            @change="checkboxAnswer">
          <div v-for="(opt, opIdx) in qsList1[hisIdx].options"
               :key="opIdx">
            <van-checkbox :name="opt.id"><span class="iico">{{ abList[opIdx] }}</span>{{ opt.c }}</van-checkbox>
            <div class="option_pic"
                 v-show="qsList1[hisIdx].item_pics[opIdx]">
              <van-image width="100%"
                         height="100%"
                         :src="
                  qsList1[hisIdx].item_pics[opIdx]
                    ? filesvrurl +
                      '/' +
                      qsList1[hisIdx].item_pics[opIdx] +
                      '.png'
                    : ''
                "
                         @click="
                  imagePreview(
                    filesvrurl + '/' + qsList1[hisIdx].item_pics[opIdx] + '.png'
                  )
                " />
            </div>
          </div>
        </van-checkbox-group>
        <!-- 判断题 -->
        <van-radio-group class="judge"
                         v-model="judgeAs"
                         v-if="qsList1[hisIdx].qsType == '判断题'"
                         @change="judgeAnswer">
          <van-radio name="1">{{
            $t("examination.examinationTxt1")
          }}</van-radio>
          <div class="option_pic"
               v-show="qsList1[hisIdx].item_pics[0]">
            <van-image width="100%"
                       height="100%"
                       :src="
                qsList1[hisIdx].item_pics[0]
                  ? filesvrurl + '/' + qsList1[hisIdx].item_pics[0] + '.png'
                  : ''
              "
                       @click="
                imagePreview(
                  filesvrurl + '/' + qsList1[hisIdx].item_pics[0] + '.png'
                )
              " />
          </div>
          <van-radio name="0">{{
            $t("examination.examinationTxt2")
          }}</van-radio>
          <div class="option_pic"
               v-show="qsList1[hisIdx].item_pics[1]">
            <van-image width="100%"
                       height="100%"
                       :src="
                qsList1[hisIdx].item_pics[1]
                  ? filesvrurl + '/' + qsList1[hisIdx].item_pics[1] + '.png'
                  : ''
              "
                       @click="
                imagePreview(
                  filesvrurl + '/' + qsList1[hisIdx].item_pics[1] + '.png'
                )
              " />
          </div>
        </van-radio-group>
        <!-- 填空题 -->
        <van-cell-group v-if="qsList1[hisIdx].qsType == '填空题'">
          <van-field v-model="completions[tkIdx]"
                     :label="'空格' + parseInt(tkIdx + 1)"
                     :placeholder="$t('examination.examinationTxt3')"
                     v-for="(tk, tkIdx) in qsList1[hisIdx].qstitle.split('____').length -
            1"
                     :key="tkIdx" />
        </van-cell-group>
        <!-- 简答题 -->
        <van-field class="brief"
                   v-if="qsList1[hisIdx].qsType == '问答题'"
                   v-model="questionAs"
                   rows="10"
                   autosize
                   label=""
                   type="textarea"
                   maxlength="500"
                   :placeholder="$t('examination.examinationTxt4')"
                   show-word-limit />
        <!-- 上传题 -->
        <!-- <van-uploader v-if="qsList[bgIdx].qsType == '附件题'"
                      v-model="item.answer"
                      multiple
                      :max-count="1">
          <van-button icon="plus"
                      type="info">上传文件</van-button>
        </van-uploader> -->
      </div>
    </div>
    <div class="footer">
      <van-button type="info"
                  v-if="can_switch_pre"
                  @click="prev">上一题</van-button>
      <van-button type="info"
                  :disabled="!cansave"
                  @click="next">{{
        $t("examination.examinationTxt5")
      }}</van-button>
    </div>
    <!-- 倒计时 -->
    <div class="endtime">
      {{daojishiStr}}
    </div>
    <exitPrompt ref="exitPrompt"
                :noAnswer="noAnswer"
                :toPath="toPath" />
  </div>
</template>

<script>
import { ImagePreview, Dialog, Toast } from "vant";
import exitPrompt from "./exitPrompt.vue";
import submitPrompt from "./submitPrompt.vue";
import {
  getCourseTestTotal,
  createUserTestInfo,
  saveTestQsInfo,
  getPreQsItemInfo
} from "@api/wxpx.js";
export default {
  components: {
    exitPrompt,
    submitPrompt,
  },
  data () {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      paperid: this.$route.query.paperid,
      mautoid: this.$route.query.mautoid, // 培训实施记录id
      teststyle: this.$route.query.teststyle, // 培训实施记录id
      examinTitle: "", // 考试标题
      lastid: "", // 上次中断的试题id（如果等于-1表示答题完成）
      totalList: [], // 考试的题型信息
      hisIdx: 0, // 历史记录题目在数组中的index
      toPath: {},
      bigOrder: [
        this.$t("examination.examinationTxt6"),
        this.$t("examination.examinationTxt7"),
        this.$t("examination.examinationTxt8"),
        this.$t("examination.examinationTxt9"),
        this.$t("examination.examinationTxt10"),
        this.$t("examination.examinationTxt11"),
        this.$t("examination.examinationTxt12"),
      ],
      abList: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      radioAs: "", // 单选题答案
      checkList: [], // 多选答案
      judgeAs: "", // 判断题答案(0和1,传参时转换)
      completions: [], // 填空题答案
      questionAs: "", // 问答题答案
      // cansave: false, // 是否可以下一题
      totalscore: 0, // 试卷总得分
      isOvertime: false, // 超时结束
      isfinish: false, // 答题结束
      qsList1: [],
      showExData: false,
      filesvrurl: "", // 图片url前缀
      chainaIndex: "一",
      chainaSelect: "",
      qsTypeIndexArr: ["一", "二", "三", "四", "五", "六"],
      finishInfo: "", // 考试结束的提示文字
      lastinfos: "",
      can_switch_pre: false,
      option_outof_order: false,
      indexAdd: 1,
      testet: null, // 考试结束日期
      daojishiStr: '剩余时间：0时0分0秒',
      QRTimer: null,
      isTimeout: false
    };
  },
  watch: {
    hisIdx: {
      handler (n) {
        console.log(this.qsList1[n].qsType, "hisIdxhisIdxhisIdxhisIdx");
      },
    },
    isOvertime: {
      handler (n) {
        if (n)        {
          let param = {
            paperid: this.paperid,
            mautoid: this.mautoid,
            teststyle: this.teststyle,
            examinTitle: this.examinTitle,
            totalscore: this.totalscore,
            isAnswer: this.qsList1.length - this.noAnswer,
            noAnswer: this.noAnswer,
            finishInfo: this.finishInfo,
            lastinfos: this.lastinfos,
          };
          this.$router.push({ path: "/submitPage", query: { param: param } });
          // this.$refs.submitPrompt.show = true
        }
      },
    },
    isfinish: {
      handler (n) {
        if (n)        {
          let param = {
            paperid: this.paperid,
            mautoid: this.mautoid,
            teststyle: this.teststyle,
            examinTitle: this.examinTitle,
            totalscore: this.totalscore,
            isAnswer: this.qsList1.length - this.noAnswer,
            noAnswer: this.noAnswer,
            finishInfo: this.finishInfo,
            lastinfos: this.lastinfos,
          };
          this.$router.push({ path: "/submitPage", query: { param: param } });
          // this.$refs.submitPrompt.show = true
        }
      },
    },
  },
  computed: {
    cansave () {
      if (
        this.radioAs != "" ||
        this.checkList.length > 0 ||
        this.judgeAs != "" ||
        this.completions.length > 0 ||
        this.questionAs != ""
      )      {
        return true;
      } else      {
        return false;
      }
    },
    // 计算试卷有多少题没有答案
    noAnswer () {
      let num = 0;
      if (!this.isfinish)      {
        // this.showExData = true;
        num = this.qsList1.length - this.hisIdx;
      }
      return num;
    },
  },
  beforeDestroy () {
    clearInterval(this.QRTimer)
  },
  methods: {
    // 考试剩余时间倒计时
    FreshTime (endData) {
      // endData = '2023-07-04 16:58:00'
      if (!endData)      {
        this.daojishiStr = '剩余时间：0时0分0秒'
        this.isTimeout = true
      } else      {
        // 如果有过期时间，则开启倒计时
        this.QRTimer = setInterval(() => {
          const endtime = new Date(endData);//结束时间 
          const nowtime = new Date();//当前时间
          const lefttime = parseInt((endtime.getTime() - nowtime.getTime()) / 1000); // 剩余时间
          if (lefttime > 0)          {
            const h = parseInt((lefttime / 3600) % 24); // 剩余小时数
            const m = parseInt((lefttime / 60) % 60); // 剩余分钟数
            const s = parseInt(lefttime % 60);    // 剩余秒数
            this.daojishiStr = '剩余时间：' + h + '时' + m + '分' + s + '秒'
          } else          {
            clearInterval(this.QRTimer)
            this.daojishiStr = '剩余时间：0时0分0秒'
            this.isTimeout = true
          }
        }, 1000)
      }
    },
    // 预览图片
    imagePreview (val) {
      ImagePreview([val]);
    },
    // 判断题
    judgeAnswer (val) {
      // this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 多选题选择答案
    checkboxAnswer (val) {
      // this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 单选题选择答案
    radioAnswer (val) {
      // this.radioAs = val
      // this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 点击返回
    onClickLeft () {
      if (this.noAnswer > 0)      {
        this.$refs.exitPrompt.show = true;
      } else      {
        // this.$router.push(`/examinInfo/${this.paperid}`);
        this.$router.push({
          path: "/examinInfo",
          query: {
            paperid: item.paperid,
            mautoid: item.autoid,
            teststyle: this.teststyle,
          },
        });
      }
    },
    prev () {
      if (this.isTimeout)      {
        // 超时直接返回首页
        Dialog.alert({
          title: '提示',
          message: '考试已超时！',
        }).then(() => {
          this.$router.push("/home");
        });
        // Toast.fail('考试已超时！');
        return
      }
      console.log(this.qsList1, 'this.qsList1');
      let flag = this.qsList1[this.hisIdx].qsflag;
      let auid = this.qsList1[this.hisIdx].autoid;
      let params = {
        autoid: auid,
        username: this.userInfo.username, // 后续登录接口有变--username
      };
      getPreQsItemInfo(params).then(res => {
        const obj = res.data[0];
        const qsres = obj.qsres;
        console.log(qsres, 'prevprevprev')
        this.qsList1.map((it, index) => {
          if (it.autoid == obj.autoid)          {
            this.hisIdx = index;
            const newobj = this.qsList1[this.hisIdx];
            if (newobj.qsflag == 0)            {
              this.radioAs = ''
              this.checkList = [];
              this.judgeAs = ''
              this.questionAs = '';
              newobj.options.forEach((item) => {
                if (qsres == item.id)                {
                  this.radioAs = item.id;
                }
              })
            } else if (newobj.qsflag == 1)            {
              this.radioAs = '';
              this.judgeAs = ''
              this.questionAs = '';
              let arr = []
              newobj.options.forEach((item) => {
                if (qsres.indexOf(item.id) != -1)                {
                  arr.push(item.id)
                }
              })
              this.checkList = arr
            } else if (newobj.qsflag == 2)            {
              this.questionAs = '';
              this.radioAs = ''
              this.checkList = [];
              qsres == 'false' ? this.judgeAs = "0" : qsres == 'true' ? this.judgeAs = "1" : this.judgeAs = ""
              // params.qsres = this.judgeAs == "0" ? false : true;
              // judgeAs
            } else if (newobj.qsflag == 4)            {
              this.radioAs = ''
              this.checkList = [];
              this.judgeAs = ''
              this.questionAs = qsres;
            }
          }
        });
        if (this.indexAdd > 1)        {
          this.indexAdd--
        }
      })
    },
    // 下一题
    next () {
      if (this.isTimeout)      {
        // 超时直接返回首页
        Dialog.alert({
          title: '提示',
          message: '考试已超时！',
        }).then(() => {
          this.$router.push("/home");
        });
        // Toast.fail('考试已超时！');
        return
      }
      let flag = this.qsList1[this.hisIdx].qsflag;
      let auid = this.qsList1[this.hisIdx].autoid;
      let params = {
        autoid: auid,
        qsres: "",
        username: this.userInfo.username, // 后续登录接口有变--username
      };

      if (this.hisIdx == this.qsList1.length - 1)      {
        // 当答到最后一题，给出提示
        Dialog.confirm({
          title: "",
          message: "是否要提交试卷？",
        })
          .then(() => {
            if (flag == 0)            {
              // 单选题
              params.qsres = this.radioAs;
              this.SaveTestQsInfo(params);
              this.radioAs = "";
            } else if (flag == 1)            {
              // 多选题
              params.qsres = this.checkList.sort().join(",");
              this.SaveTestQsInfo(params);
              this.checkList = [];
            } else if (flag == 2)            {
              // 判断题
              params.qsres = this.judgeAs == "0" ? false : true;
              this.SaveTestQsInfo(params);
              this.judgeAs = "";
            } else if (flag == 3)            {
              // 填空题
              params.qsres = this.completions.join("|");
              this.SaveTestQsInfo(params);
              this.completions = [];
            } else if (flag == 4)            {
              // 问答题
              params.qsres = this.questionAs;
              this.SaveTestQsInfo(params);
              this.questionAs = "";
            } else if (flag == 5)            {
              // 附件题
            }
          })
          .catch(() => {
            return false;
          });
      } else      {
        if (flag == 0)        {
          // 单选题
          params.qsres = this.radioAs;
          this.SaveTestQsInfo(params);
          this.radioAs = "";
        } else if (flag == 1)        {
          // 多选题
          params.qsres = this.checkList.sort().join(",");
          this.SaveTestQsInfo(params);
          this.checkList = [];
        } else if (flag == 2)        {
          // 判断题
          params.qsres = this.judgeAs == "0" ? false : true;
          this.SaveTestQsInfo(params);
          this.judgeAs = "";
        } else if (flag == 3)        {
          // 填空题
          params.qsres = this.completions.join("|");
          this.SaveTestQsInfo(params);
          this.completions = [];
        } else if (flag == 4)        {
          // 问答题
          params.qsres = this.questionAs;
          this.SaveTestQsInfo(params);
          this.questionAs = "";
        } else if (flag == 5)        {
          // 附件题
        }
        this.indexAdd++
      }
    },
    // 查询课程考试信息汇总
    GetCourseTestTotal () {
      getCourseTestTotal({ paperid: this.paperid }).then((res) => {
        if (res.iserror == 0)        {
          // this.bgurl = res.baseinfo[0].bgurl
          this.examinTitle = res.baseinfo[0].papername;
          this.can_switch_pre = res.baseinfo[0].can_switch_pre;
          this.option_outof_order = res.baseinfo[0].option_outof_order;
          this.totalList = res.data;
          this.CreateUserTestInfo(this.totalList);
          // this.taketime = res.baseinfo[0].taketime
          // this.totalscore = res.baseinfo[0].totalscore
        }
      });
    },
    randomSort (array) {
      let length = array.length;
      // 不是数组或者数组长度小于等于1，直接返回
      if (!Array.isArray(array) || length <= 1) return;
      for (let index = 0; index < length - 1; index++)      {
        let randomIndex = Math.floor(Math.random() * (length - index)) + index;
        [array[index], array[randomIndex]] = [array[randomIndex], array[index]];
      }
      return array;
    },

    // 生成当前用户当前课程考试试卷信息（并返回试题明细和上次中断序号信息）
    CreateUserTestInfo (totalList) {
      createUserTestInfo({
        mautoid: this.mautoid,
        empid: this.userInfo.empid,
        username: this.userInfo.username,
        paperid: this.paperid,
        teststyle: this.teststyle,
        //后续登录接口有变--username
      }).then((res) => {
        if (res.iserror == 0)        {
          // 考试结束时间
          this.testet = res.lastinfo[0].testet;
          this.FreshTime(this.testet)
          // 第一次进来查询时，如果没有答完就，获取记录上次的id在结果数组中的index，用来显示上次未答的题
          this.lastid = res.lastinfo[0].lastid;
          this.filesvrurl = res.lastinfo[0].filesvrurl;
          if (this.lastid > 0)          {
            this.showExData = true;
            res.paperinfo.map((it, index) => {
              if (it.autoid == this.lastid)              {
                this.hisIdx = index;
              }
            });
          } else if (this.lastid == -1)          {
            // 答题结束
            // 但是没有返回总分，咋显示？？？
            this.totalscore = res.lastinfo[0].totalscore;
            this.lastinfos = res.lastinfo[0].info;
            this.isfinish = true;
            this.showExData = false;
          }
          if (res.paperinfo.length > 0)          {
            res.paperinfo.forEach((item) => {
              // 0单选，1多选，2判断，3填空，4问答，5附件
              for (let i = 0; i < totalList.length; i++)              {
                if (item.qsflag == 0 && totalList[i].qsflagname == "单选")                {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                  item.item_pics = item.item_pic_guid
                    ? item.item_pic_guid.split(",")
                    : [];
                } else if (
                  item.qsflag == 1 &&
                  totalList[i].qsflagname == "多选"
                )                {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                  item.item_pics = item.item_pic_guid
                    ? item.item_pic_guid.split(",")
                    : [];
                }
                if (item.qsflag == 2 && totalList[i].qsflagname == "判断")                {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                  item.item_pics = item.item_pic_guid
                    ? item.item_pic_guid.split(",")
                    : [];
                }
                if (item.qsflag == 3 && totalList[i].qsflagname == "填空")                {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                }
                if (item.qsflag == 4 && totalList[i].qsflagname == "问答")                {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                }
                if (item.qsflag == 5 && totalList[i].qsflagname == "附件")                {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                }
              }
              if (item.qsflag == 0)              {
                item.qsType = "单选题";
                let arr = item.qsitemlist.split("|");
                let arr2 = []
                for (let i = 0; i < arr.length; i++)                {
                  let obj = {
                    id: this.abList[i],
                    c: arr[i]
                  }
                  arr2.push(obj);
                }
                if (this.option_outof_order)                {
                  item.options = this.randomSort(arr2);
                } else                {
                  item.options = arr2;
                }

              } else if (item.qsflag == 1)              {
                item.qsType = "多选题";
                let arr = item.qsitemlist.split("|");
                let arr2 = []
                for (let i = 0; i < arr.length; i++)                {
                  let obj = {
                    id: this.abList[i],
                    c: arr[i]
                  }
                  arr2.push(obj);
                }
                if (this.option_outof_order)                {
                  item.options = this.randomSort(arr2);
                } else                {
                  item.options = arr2;
                }
              }
              if (item.qsflag == 2)              {
                item.qsType = "判断题";
              }
              if (item.qsflag == 3)              {
                item.qsType = "填空题";
              }
              if (item.qsflag == 4)              {
                item.qsType = "问答题";
              }
            });
            this.qsList1 = res.paperinfo;
            let sArr = this.sortArr(this.qsList1, "qsflag");
            for (let j = 0; j < sArr.length; j++)            {
              for (let k = 0; k < this.qsList1.length; k++)              {
                if (sArr[j][0].qsflag == this.qsList1[k].qsflag)                {
                  this.qsList1[k].qsTypeIndex = j;
                }
              }
            }
            console.log(this.qsList1, "this.qsList1");
          }
        }
      });
    },
    sortArr (arr, str) {
      var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;

      // 按照特定的参数将数组排序将具有相同值得排在一起
      arr = arr.sort(function (a, b) {
        var s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });

      if (arr.length)      {
        _tmp = arr[0][str];
      }
      // console.log( arr );
      // 将相同类别的对象添加到统一个数组
      for (var i in arr)      {
        if (arr[i][str] === _tmp)        {
          _t.push(arr[i]);
        } else        {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      // 将最后的内容推出新数组
      _arr.push(_t);
      return _arr;
    },
    // 保存试卷某个试题答案信息接口（考试只能一直点下一题，不能切换到上一题）
    SaveTestQsInfo (params) {
      console.log(params, 'paramsparams')
      saveTestQsInfo(params).then((res) => {
        if (res.iserror == 0)        {
          // 下一题的题号以及总得分；iflag:-1:表示正常答题结束，>0:表示下一题序号，=0:表示超时答题结束；// totalscore:如果iflag=-1或0，则totalscore表示试卷总得分；
          let total = res.total;
          if (total.length > 0)          {
            this.finishInfo = total[0].finishinfo;
            const iflag = total[0].iflag;
            if (iflag == -1)            {
              // 答题结束
              this.totalscore = total[0].totalscore;
              this.showExData = false;
              this.isfinish = true;
            } else if (iflag > 0)            {
              const qsInfo = res.qsinfo[0];
              const qsres = qsInfo.qsres;
              this.showExData = true;
              // 下一题
              this.qsList1.map((it, index) => {
                if (it.autoid == iflag)                {
                  this.hisIdx = index;
                  const newobj = this.qsList1[this.hisIdx];
                  if (newobj.qsflag == 0)                  {
                    this.radioAs = ''
                    this.checkList = [];
                    this.judgeAs = "";
                    this.questionAs = '';
                    newobj.options.forEach((item) => {
                      if (qsres == item.id)                      {
                        this.radioAs = item.id;
                      }
                    })
                  } else if (newobj.qsflag == 1)                  {
                    this.radioAs = '';
                    let arr = []
                    this.judgeAs = "";
                    this.questionAs = '';
                    newobj.options.forEach((item) => {
                      if (qsres.indexOf(item.id) != -1)                      {
                        arr.push(item.id)
                      }
                    })
                    this.checkList = arr
                  } else if (newobj.qsflag == 2)                  {
                    this.radioAs = ''
                    this.checkList = [];
                    this.questionAs = '';
                    qsres == 'false' ? this.judgeAs = "0" : qsres == 'true' ? this.judgeAs = "1" : this.judgeAs = ""
                  } else if (newobj.qsflag == 4)                  {
                    this.radioAs = ''
                    this.checkList = [];
                    this.judgeAs = ''
                    this.questionAs = qsres;
                  }
                }
              });
            } else if (iflag == 0)            {
              // 超时
              this.totalscore = total[0].totalscore;
              this.isOvertime = true;
              this.showExData = false;
            }
          }
        }
      });
    },
    prevTestQsInfo (params) {

    },
  },
  created () {
    window.T = this;
    this.toPath = {
      path: "/examinInfo",
      query: {
        paperid: this.paperid,
        mautoid: this.mautoid,
        teststyle: this.teststyle,
      },
    };
    this.GetCourseTestTotal();
  },
};
</script>

<style lang="less" scoped>
.examination {
  background: #fff;
  height: 100vh;

  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #ffffff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .banner {
    height: 124px;
    background: url("~@/assets/img/examination1.png") no-repeat;
    background-size: cover;
    background-position-y: -150px;
  }
  .small_content {
    max-height: calc(100% - 492px) !important;
  }
  .content {
    font-size: 36px;
    font-family: Source Han Sans SC;
    padding: 30px;
    max-height: calc(100% - 400px);
    overflow: auto;
    .qsType {
      margin-bottom: 28px;
      .typeName {
        margin-right: 15px;
      }
      .grade {
        color: #2b8df0;
      }
    }
    .qsContent {
      padding: 36px 0;
      border-top: 1px solid #ccc;
      color: #333;
      font-size: 32px;
      .qsTit {
        margin-bottom: 42px;
      }
      .title_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      // 单选样式
      .singleChoice {
        padding-left: 1em;
        .option_pic {
          margin-bottom: 20px;
          .van-image {
            border: 1px solid #e1e6f0;
          }
        }
        .van-radio {
          margin-bottom: 40px;
        }
        .van-radio {
          /deep/ .van-radio__icon--round {
            display: none;
          }
          /deep/.van-radio__label {
            margin-left: 0px;
            white-space: pre-wrap;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-radio[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 多选样式
      .van-checkbox-group {
        padding-left: 1em;
        .van-checkbox {
          margin-bottom: 40px;
        }
        .van-checkbox {
          /deep/ .van-checkbox__icon--round {
            display: none;
          }
          /deep/.van-checkbox__label {
            margin-left: 0px;
            white-space: pre-wrap;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-checkbox[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-checkbox__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 判断题样式
      .judge {
        padding-left: 1em;
        .option_pic {
          margin-bottom: 20px;
          .van-image {
            border: 1px solid #e1e6f0;
          }
        }
        .van-radio {
          margin-bottom: 40px;
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 简答题样式
      .brief {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
      }
      // 上传题样式
      /deep/.van-uploader {
        width: 100%;
        /deep/.van-uploader__wrapper {
          display: flex;
          justify-content: flex-end;
          flex-flow: row-reverse;
          // flex-direction: column-reverse;
          /deep/.van-uploader__input-wrapper {
            // background: red;
            width: 50%;
            // width:80%;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .van-button {
      width: 80%;
      margin-bottom: 20px;
      font-size: 32px;
    }
  }
  .endtime {
    width: 100%;
    font-size: 32px;
    color: red;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
}
</style>
